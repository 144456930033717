<template>
  <div class="w-full">
    <div class=" permission-title">
      <div class="flex-none"> {{ $t('permissions.sections.' + section.replaceAll(" ", "_")) }}</div>
      <div class="permission-title-line"></div>
    </div>


    <div class="d-flex flex-wrap">
      <b-col v-for="item in permissions" :key="item.id" sm="6"
             cols="12" md="3">
        <wameed-checkbox
            :title='checkBoxLabel(item.name)'
            text="label" :value="item.id"
            :checked="selectedPermissions.includes(item.id)"

            @onChange="()=>onPermissionCheck(item.id)"
        />
      </b-col>
    </div>
  </div>
</template>

<script>

import {WameedCheckbox} from 'wameed-ui/dist/wameed-ui.esm'

export default {
  components: {
    WameedCheckbox,
  },
  props: {
    section: String,
    permissions: Array,
    selectedPermissions: Array,
  },


  methods: {
    checkBoxLabel(name) {
      return this.$i18n.t("permissions." + name.replace("(", "_").replace(")", "").replaceAll(" ", "_").replaceAll("__", "_"))
    },

    onPermissionCheck(id) {
      this.$emit('onCheck', id)
    },


  },

  created() {

  }

};
</script>

<style>
.custom-input{
  align-items: center;
  font-size:14px;
  line-height: 26px;
}
input[type=checkbox].wameed-checkbox {
  transform: unset !important;
}
</style>